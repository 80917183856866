var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ci-table"},[_c('gl-table-lite',{attrs:{"fields":_vm.$options.tableFields,"items":_vm.pipelines,"tbody-tr-class":"commit","tbody-tr-attr":_vm.$options.TBODY_TR_ATTR,"stacked":"lg","fixed":""},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('span',{staticClass:"gl-display-block gl-lg-display-none!"},[_vm._v(_vm._s(_vm.s__('Pipeline|Actions')))]),_vm._v(" "),_vm._t("table-header-actions")]},proxy:true},{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,class:field.columnClass})})}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('pipelines-status-badge',{attrs:{"pipeline":item,"view-type":_vm.viewType}})]}},{key:"cell(pipeline)",fn:function(ref){
var item = ref.item;
return [_c('pipeline-url',{attrs:{"pipeline":item,"pipeline-schedule-url":_vm.pipelineScheduleUrl,"pipeline-key":_vm.pipelineKeyOption.key}})]}},{key:"cell(triggerer)",fn:function(ref){
var item = ref.item;
return [_c('pipeline-triggerer',{attrs:{"pipeline":item}})]}},{key:"cell(stages)",fn:function(ref){
var item = ref.item;
return [_c('pipeline-mini-graph',{attrs:{"downstream-pipelines":item.triggered,"pipeline-path":item.path,"stages":item.details.stages,"update-dropdown":_vm.updateGraphDropdown,"upstream-pipeline":item.triggered_by},on:{"pipelineActionRequestComplete":_vm.onPipelineActionRequestComplete,"miniGraphStageClick":_vm.trackPipelineMiniGraph}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('pipeline-operations',{attrs:{"pipeline":item,"canceling-pipeline":_vm.cancelingPipeline}})]}}],null,true)}),_vm._v(" "),_c('pipeline-stop-modal',{attrs:{"pipeline":_vm.pipeline},on:{"submit":_vm.onSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }