var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-container gl-mt-1"},[(_vm.isScheduled)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"href":_vm.pipelineScheduleUrl,"target":"__blank","title":_vm.__('This pipeline was triggered by a schedule.'),"variant":"info","size":"sm","data-testid":"pipeline-url-scheduled"}},[_vm._v(_vm._s(_vm.__('Scheduled')))]):_vm._e(),_vm._v(" "),(_vm.pipeline.flags.latest)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.__('Latest pipeline for the most recent commit on this branch'),"variant":"success","size":"sm","data-testid":"pipeline-url-latest"}},[_vm._v(_vm._s(_vm.__('latest')))]):_vm._e(),_vm._v(" "),(_vm.pipeline.flags.merge_train_pipeline)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.s__(
        'Pipeline|This pipeline ran on the contents of this merge request combined with the contents of all other merge requests queued for merging into the target branch.'
      ),"variant":"info","size":"sm","data-testid":"pipeline-url-train"}},[_vm._v(_vm._s(_vm.s__('Pipeline|merge train')))]):_vm._e(),_vm._v(" "),(_vm.pipeline.flags.yaml_errors)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.pipeline.yaml_errors,"variant":"danger","size":"sm","data-testid":"pipeline-url-yaml"}},[_vm._v(_vm._s(_vm.__('yaml invalid')))]):_vm._e(),_vm._v(" "),(_vm.pipeline.flags.failure_reason)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.pipeline.failure_reason,"variant":"danger","size":"sm","data-testid":"pipeline-url-failure"}},[_vm._v(_vm._s(_vm.__('error')))]):_vm._e(),_vm._v(" "),(_vm.pipeline.flags.auto_devops)?[_c('gl-link',{attrs:{"id":_vm.autoDevopsTagId,"tabindex":"0","data-testid":"pipeline-url-autodevops","role":"button"}},[_c('gl-badge',{attrs:{"variant":"info","size":"sm"}},[_vm._v("\n        "+_vm._s(_vm.__('Auto DevOps'))+"\n      ")])],1),_vm._v(" "),_c('gl-popover',{attrs:{"target":_vm.autoDevopsTagId,"triggers":"focus","placement":"top"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"gl-font-weight-normal gl-line-height-normal"},[_c('gl-sprintf',{attrs:{"message":_vm.__(
                'This pipeline makes use of a predefined CI/CD configuration enabled by %{strongStart}Auto DevOps.%{strongEnd}'
              )},scopedSlots:_vm._u([{key:"strong",fn:function(ref){
              var content = ref.content;
return [_c('b',[_vm._v(_vm._s(content))])]}}],null,false,1478500124)})],1)]},proxy:true}],null,false,773303373)},[_vm._v(" "),_c('gl-link',{attrs:{"href":_vm.autoDevopsHelpPath,"data-testid":"pipeline-url-autodevops-link","target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.__('Learn more about Auto DevOps'))+"\n      ")])],1)]:_vm._e(),_vm._v(" "),(_vm.pipeline.flags.stuck)?_c('gl-badge',{attrs:{"variant":"warning","size":"sm","data-testid":"pipeline-url-stuck"}},[_vm._v(_vm._s(_vm.__('stuck')))]):_vm._e(),_vm._v(" "),(_vm.pipeline.flags.detached_merge_request_pipeline)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.s__(
        "Pipeline|This pipeline ran on the contents of this merge request's source branch, not the target branch."
      ),"variant":"info","size":"sm","data-testid":"pipeline-url-detached"}},[_vm._v(_vm._s(_vm.s__('Pipeline|merge request')))]):_vm._e(),_vm._v(" "),(_vm.isInFork)?_c('gl-badge',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],attrs:{"title":_vm.__('Pipeline ran in fork of project'),"variant":"info","size":"sm","data-testid":"pipeline-url-fork"}},[_vm._v(_vm._s(_vm.__('fork')))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }